<template>
  <el-drawer title="建设清单详情" :visible="drawerShow" :direction="`rtl`" v-if="drawerShow" size="40%" :before-close="visibleClose" append-to-body>
    <div class="detail-box">
      <el-descriptions colon  :column="1">
        <el-descriptions-item label="项目名称">{{constructionListData.projectName}}</el-descriptions-item>
        <el-descriptions-item label="项目金额"><span class="projectValue">{{constructionListData.projectValue}} </span> 元</el-descriptions-item>
        <el-descriptions-item label="具体内容">{{constructionListData.concreteContent || '--'}}</el-descriptions-item>
        <el-descriptions-item label="改造前" label-style="width: 50px;">
          <div v-if="constructionListData.beforeImgs.length">
            <img v-for="(item) in constructionListData.beforeImgs" style="width: 90%;" :key="item.key" :src="item.value" alt="">
          </div>
          <span v-else>暂无图片</span>
        </el-descriptions-item>
        <el-descriptions-item label="改造后"  label-style="width: 50px;">
          <div v-if="constructionListData.afterImgs.length">
            <img v-for="(item) in constructionListData.afterImgs" style="width: 90%" :key="item.key" :src="item.value" alt="">
          </div>
          <span v-else>暂无图片</span>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </el-drawer>
</template>

<script>
  export default {
    // 订单详情
    nama: 'constructionListDetail',
    props: {
      drawerShow: {
        type: Boolean,
        default: false,
      },
      constructionListData: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    data() {
      return {
      }
    },
    methods: {
      visibleClose() {
        this.$emit('update:drawerShow', false)
      },
    },
  }
</script>

<style lang="scss" scoped>
  .detail-box {
    padding: 0 20px;
  }
  .projectValue {
    color: #FF4242;
  }
  /deep/ .el-descriptions-item__content {
    flex: 1 !important;
  }
</style>
